@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
/**
 *  PLEASE DO NOT ADD GLOBAL CSS UNLESS
 *  IT IS ABSOLUTELY NECESSARY TO
 *
 *  We should be using Chakra components, pre-defined styles
 *  and themes as much as possible.
 *
 *  If you do add a style, please document why it needs CSS here,
 *  and when we can refactor it.
 *
 */

/**
 * For rich-text editor, it is too complicated to convert the HTML/CSS
 * of the toolbar to Chakra right now
 *
 */

.rdw-image-modal-upload-option-label {
    word-break: break-all;
}
.textClass div:nth-child(1) {
    z-index: auto !important;
}
.rdw-editor-toolbar {
    background: inherit !important;
    font-size: 12px;
    border: unset !important;
}
.rdw-editor-toolbar img {
    width: 10px;
}
/* .rdw-editor-main {
    max-height: 350px;height: auto;
} */
/* .rdw-editor-main .DraftEditor-editorContainer {
    max-height: 212px !important;
    overflow: scroll;
} */
/* react-searchable-dropdown css  third party plugin */

/* react-searchable-dropdown css third party plugin */

/* Webchat css  */
div[role='log'] ul {
    padding: 15px !important;
    font-size: 14px;
}
div[role='complementary'] .main {
    position: relative;
    min-height: 50px;
}
div[role='complementary'] .main form {
    background: #f5f5f5;
    position: absolute;
    left: 10px;
    right: 10px;
    top: 5px;
    padding: 11px 0;
    border-radius: 50px;
    overflow: hidden;
}
div[role='complementary'] .main form input[type='text'] {
    background: #f5f5f5;
    padding: 0 36px;
}
div[role='complementary'] .main div:first-child {
    position: absolute;
    left: 17px;
    z-index: 11;
    top: 12px;
}
div[role='complementary'] .main div:last-child {
    position: absolute;
    right: 4px;
    z-index: 11;
    top: 12px;
}
.webchat__bubble__content {
    padding: 0 10px;
    max-width: -webkit-max-content;
    max-width: max-content;
    display: flex;
    align-items: center;
}
div[role='complementary'] button.css-2m2878 {
    font-size: 12px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
}
div[role='complementary'] .ac-textBlock {
    color: #787878 !important;
}
div[role='complementary'] {
    margin-left: 10px;
    margin-right: 10px;
}
div[role='complementary'] .webchat__bubble__content img {
    position: relative !important;
}
.pulse {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #e74c3c;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(231, 76, 60, 0.5);
    animation: pulse 1.4s infinite;
}
.pulse:hover {
    animation: none;
}
.react-dropdown-select-dropdown {
    width: 100% !important;
    border: none !important;
    /* color: #333 !important; */
}
@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0.5);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(231, 76, 60, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(231, 76, 60, 0);
    }
}
:root :focus {
    z-index: 1;
    box-shadow: none !important;
}

.react-dropdown-select-dropdown-handle {
    position: absolute;
    right: 8px;
}
.react-dropdown-select-content span {
    width: calc(100% - 30px);
    margin: 0 5px 2px 0px !important;
}
.react-dropdown-select-option-remove {
    width: 22px !important;
    background: #e74c3c;
}
.rdw-editor-wrapper .rdw-dropdown-optionwrapper:hover {
    background-color: #0074d9;
    color: #fff;
}

.rdw-editor-wrapper .rdw-dropdown-optionwrapper {
    background: #0074d9;
    color: #fff;
}
.rdw-editor-wrapper .rdw-dropdown-selectedtext {
    color: #000;
}
.rdw-editor-wrapper .rdw-dropdownoption-default.rdw-dropdownoption-active,
.rdw-dropdownoption-default.rdw-dropdownoption-highlighted {
    background-color: rgba(255, 255, 255, 0.8);
}
.rdw-editor-wrapper .rdw-dropdown-wrapper {
    height: 21px !important;
}

/* date picker css */
.react-datepicker-wrapper .react-datepicker__input-container input {
    background: transparent;
    border: 1px solid #ccc;
    height: 28px;
    padding: 4px;
}

/* Slider Css */
.container-slider {
    max-width: 100%;
    height: calc(100vh - 36px);
    margin: 10px auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
@media screen and (max-width: 700px) {
    .container-slider {
        margin: 100px 10px 0;
    }
}
.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
}
.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.active-anim {
    opacity: 1;
}

.btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #f1f1f1;
    border: 1px solid rgba(34, 34, 34, 0.287);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.prev {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
    position: absolute !important;
    background-color: gray !important;
}
.next {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
    position: absolute !important;
    background-color: gray !important;
}
/* Slider Css */

.resizer-elm > div {
    /* background: #0056D3; */
    width: 2px !important;
    border-radius: 10px;
    height: 98% !important;
    right: -6px !important;
    top: 7px !important;
    cursor: ew-resize !important;
}
.resizer-elm > div:hover {
    background: #0056d3;
    cursor: ew-resize !important;
}
.noteList a {
    color: blue;
}
.rdw-option-wrapper {
    color: black;
}
.rdw-image-modal {
    color: black;
    left:unset;
    right:2px;
}
/* .rdw-image-modal-btn:nth-of-type(2) {
  display: none;
} */
.dynamic-field-val span.react-dropdown-select-option {
    width: auto;
}
.dynamic-field-val .react-dropdown-select {
    width: 235px;
}
.react-dropdown-select .react-dropdown-select-loading:after {
    margin-right: 20px;
}
.webchat__bubble__content span {
    width: auto !important;
}

.chakra-collapse {
    overflow: visible !important;
}

.css-o36nok-DropDown {
    top: 43px !important;
}

/* .react-dropdown-select-dropdown{
  top: 43px !important;
} */


.dropdown-multiselect div span {
    width: auto !important;
}

.css-3tw5vh .public-DraftStyleDefault-block{
  color: inherit !important;
}

.chakra-ui-dark .rdw-link-modal{
  background-color: #1d1d1d;
}

#linkTitle{
  color: #000;
}

#linkTarget{
  color: #000;
}

.rdw-link-modal{
  height: auto !important;
  box-shadow: 10px 10px 40px -11px rgb(231 232 245 / 80%) !important;
  border-radius: 10px !important;
  border: 1px solid #f1f1f1 !important;
}

button.rdw-link-modal-btn:nth-child(2){
  background: transparent !important;
  border: 1px solid #e3e3e3 !important;
  color: #999 !important;
}

button.rdw-link-modal-btn:nth-child(1){
  background: #90cdf4 !important;
  border: 1px solid #90cdf4 !important;
  color: #fff !important;
}

.rdw-link-modal-btn{
  height: 26px !important;
}

button.rdw-link-modal-btn:disabled{
  background: #ece9e9 !important;
  border: 1px #ece9e9 solid !important;
  color: #999 !important;
  font-weight: 600 !important; 
}


.webchat__bubble__content span{
  width: auto !important;
} 

/*diff checker css overwrite for dark theme  */
.css-1n5o7vh-diff-container {
  background: inherit !important;
}
.css-17uc4if-title-block {
  background: inherit !important;
}
.css-17uc4if-title-block .css-o1u8iu-content-text {
  color: inherit !important;
}

.css-10regm7-empty-line {
  background-color: inherit !important;
}
.css-1ks9aww-gutter {
  background: inherit !important;
}
.css-h84nzi-line-number {
  color: inherit !important;
}
.css-1n5o7vh-diff-container {
  border: 1px solid #e2e2e2 !important;
}
.css-1caozzd-split-view .css-vl0irh-content {
  border-right: 1px solid #e2e2e2 !important;
}
.css-1ks9aww-gutter.css-cnnxkz-diff-added {
  opacity: .5;
  color: inherit;
}
.css-1ks9aww-gutter.css-rq9a2a-diff-removed {
  color: inherit;
  opacity: .5;
}
.css-ye9jxm .public-DraftStyleDefault-block {
    color: inherit !important;
}
.rdw-suggestion-dropdown {
    background: inherit;
}
.chakra-ui-dark .elementToProof span, .chakra-ui-dark .elementToProof div {
    color: #fff !important;
    background: inherit !important;
}
.chakra-ui-dark #Signature b a{color: #034FBD !important;}
.chakra-ui-dark #Signature b {background: transparent !important;}
.elementToProof p {
    background: inherit !important;
    color: inherit;
}
.chakra-ui-dark .DraftEditor-root span {
    background: inherit !important;
    color: inherit;
}
.css-3tw5vh .public-DraftStyleDefault-block span a{
    color: #034FBD !important;
}
.rdw-mention-link {
    background-color: inherit
}


.chakra-ui-dark .x_elementToProof span {
    background-color: inherit !important;
    color: inherit !important;
}
.elementToProof {
     color: inherit !important
}
.chakra-ui-dark #Signature div {
    background-color: inherit !important;
    color: inherit !important
}
.chakra-ui-dark #x_Signature div {
    background-color: inherit !important;
    color: inherit !important
}
.chakra-ui-dark #Signature span {
    background-color: inherit !important;
} 
.chakra-ui-dark #x_Signature b a{color: #034FBD !important;}
.chakra-ui-dark #x_Signature b {background: transparent !important;}

.rdw-suggestion-dropdown{
    min-width: 200px !important;
}

.service-team-dropdown .react-dropdown-select {
    width: 12rem;
}

.chakra-ui-dark .react-dropdown-select-item{
    color: #fff !important;
} 

.quickNote-description > p, h1, h3, h4, h5, h6{
    width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}

.quickNote-description ul,
.quickNote-description li 
{
    width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}

.quickNote-description ul,
.quickNote-description li 
{
    width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}

.chakra-ui-dark .progress-bar{
    border: 1px solid #3f444e;
}


.chakra-ui-dark .react-dropdown-select-item{
    color: #fff !important;
    background-color: #2D3748 !important;
    border-color: #2D3748 !important;
} 

.chakra-ui-dark .react-dropdown-select-item:hover{
    color: #fff !important;
    background-color: #4a5468 !important;
} 

.chakra-ui-dark .react-dropdown-select-item-selected, 
.chakra-ui-dark .react-dropdown-select-item-selected:hover{
    background-color: #0074D9 !important;
}

.chakra-ui-dark .chakra-form-control .chakra-skeleton p {
    color: inherit !important;
}

.monaco-editor .no-user-select .mac  .showUnused .showDeprecated .vs{
    width: 30vw !important;
    margin-top: 5vh !important;
    }

.WordSection1 > *{
    color: inherit !important;
}

.MsoNormal{
    color: inherit !important;
}

span.wysiwyg-mention{
    color: #556fff !important;
    font-weight: bold;
}

.chakra-ui-dark .chakra-form-control .chakra-skeleton p {
    color: #fff !important;
}

.chakra-ui-dark  .css-of6tae  {
    background-color: #2D3748 !important;
}
.chakra-ui-dark .chakra-form-control .chakra-skeleton p {
    color: #fff !important;
}
.css-k8qd60{
    padding: 10px !important;
}
.chakra-ui-dark .higilightEle {
    background-color: #0074D9 !important;
    color: #fff!important;
}
.higilightEle {
    background-color: #0074D9 !important;
    color: #fff !important;
}
.chakra-ui-dark .assignedListBg {
    color: #fff !important;
    background-color: #2D3748 !important;
    border-color: #2D3748 !important;
}
.assignedListBg:hover{
    background: rgba(0, 116, 217, 0.1);
    outline: none;
}
.chakra-ui-dark .assignedListBg:hover {
    background:  #4a5468 !important;
    outline: none;
}
.assigntomelabel {
    color: #034FBD;
}
.assigntomelabel:hover {
    text-decoration: underline;
}
.chakra-ui-dark .assigntomelabel {
    -webkit-text-decoration: #034FBD;
            text-decoration: #034FBD;
}
.chakra-ui-dark .assigntomelabel:hover {
    text-decoration: underline;
}    
.inbox-table-view .react-draggable{
    width: initial !important;
}
.isChecked .react-draggable{
    width: 68% !important;
}
.inbox-table-view .search-filter-wrapper{
    display: flex !important;
    flex-direction: row !important;
    width: 60% !important;
}
.inbox-table-view .my-box{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
}
.inbox-table-view .notification-container{
    width: 20% !important;
}
.inbox-table-view .notification-container ul{
    --chakra-wrap-x-spacing: none !important;
}
.inbox-table-view .notification-container ul li{
   align-items: center !important;
}
.radio-container .chakra-radio input {
    display: none;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR{
    margin-left: 30px;
}

.dropDown-filter-renderer > div {
    /* styles for the direct child element with class .dropDown-filter-renderer */
    z-index: 2; /* Replace 100 with the desired value */
}
.filterDatePicker{width: 192px;}

.queue-dropdown span[role="listitem"]{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.css-r6z5ec {
    z-index: 10 !important;
}

.emailEditor table{width: 100%!important;}


.attachment input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
 

.chakra-ui-dark .editorfooter {
    background-color: #2d3748;
}

.chakra-ui-dark .editorfooter .editormenubtn {
    background: transparent !important;
}

.editorfooter {
    background-color: #e2e8f0;
}

.chakra-ui-dark .editorfooter .listItm {
    background-color: rgb(226, 232, 240);
    color: #808080;
}



.editorfooter .listItm {
    background-color: #e2e8f0 !important;
    color: #808080;
}

.pop_over {
    position: absolute !important;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(-8px, 48px) !important;
}

.infobtn  {
    /* inset: -96px;
    transform: translate(-7px, 48px);
    position: relative; */
    left: -21px;
    
}

/* .editorfooter .listItm:hover {
    background-color: #fff !important;
} */



.rdw-link-modal  {
    left: -80px;
}

.tableView {
    padding-bottom: 2rem !important;
}

.tableView .asc_icon {
    position: relative !important;
    top: 3px !important;
    color: #a0a8b4;
}

.tableView .desc_icon {
    position: relative !important;
    top: -4px !important;
    color: #a0a8b4;
}

.tableView .asc_icon:hover {
    color: #00000080
}

.tableView .desc_icon:hover {
    color: #00000080
}
.infoClass{
    margin:0 1% !important
}
.chakra-stack .chakra-popover__popper{
    z-index: 100;
}

.notesForDark a:not(:has(img)):not(:empty) {
    color: #556fff !important;
    font-weight: bold;
    background-color: #ecedf0;
    border: 1px solid transparent;
    border-radius: 20px
}
iframe{height: 0px !important;}


/* PRD-969 */
.agent-container{color:#6b7280;}
.ticket-tabs {
	position: relative;
	display: inline-block;
}
.ticket-tabs:hover{
    background:none;
    color:#034FBD;
}

.ticket-tabs:hover .action-btn-left, .ticket-tabs:hover .action-btn-right {
	display: block;
}

.ticket-tabs .action-btn-left {
    padding-top: 3px;
    padding-right: 0px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    left: 0;
    top: 0;
	display: none;
}

.ticket-tabs .action-btn-right {
    padding-top: 3px;
    padding-right: 2px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    right: 0;
    top: 0;
	display: none;
}
.ticket-tabs .action-btn-right-simple {
    padding-top: 3px;
    padding-right: 2px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    right: 0;
    top: 0;
}

.ticket-tabs .image-btn-left {
    padding-top: 3px;
    padding-right: 0px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    left: 0;
    top: 0;
}

.ticket-tabs .image-btn-right {
    padding-top: 3px;
    padding-right: 0px;
    position: absolute;
    font-size: 16px;
    /* color: white;
    background: #034FBD; */
    right: 0;
    top: 0;
}

.schedule-datepicker input{
    border-radius: 5px;
    width: 100%;
}

.schedule-timepicker input {
    border-radius: 5px;
    width: 100%;
}
.react-datepicker-wrapper{
    width: 100%;
    font-size: 10px;
}

span.data-mention{
    color: #556fff !important;
    font-weight: bold !important; 
    padding: 1px 4px !important;
}



.divScroll {
    overflow-y: scroll;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.2);
        border-radius: 5px;
      }
    scrollbar-width: none;
  }
.divScroll:hover {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,.2);
      border-radius: 5px;
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,0.2) rgba(0,0,0,0.1);
  }
  
  .ticket_detail_container .listCheck{display:none}
  .ticket_detail_container:hover .listCheck{
	display: block;
}

.queue-dropdown .react-dropdown-select-dropdown {
    top: 43px !important;
}

.react-dropdown-select-content span {
    width: auto !important;
}
.emailSignEditor{
    border: 1px solid lightgrey;
}

.serviceTeamSelect .react-dropdown-select-content{
    justify-content: center !important;
}
.css-5skhoe{
    height: 367px !important;
}
.emailDescCl ol{
    padding: revert !important;
}
.emailDescCl ul{
    padding: revert !important;
}
.emailDescCl div{
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
.emailBodyLink{
    color: #4f8af5 !important;
}
/* .css-1h24s7m { */
/* overflow: scroll !important; */
/* } */
/* category component blank space fix  */
/* .css-td0epc{
    overflow: scroll !important;
}
.categoryScrollFix{
    overflow: scroll !important;
}  */

.iframe-div{
    border: 2px solid #D5CC5A; overflow: hidden; margin: 15px auto; max-width: 575px;
}
.iframe-div-content{border: 0px none; margin-left: -36px; height: 812px; margin-top: -486px; width: 650px;}
.watchersList .customDropdown .react-dropdown-select-option-label{
    width: 170px !important;
}
.watchersList .customDropdown .react-dropdown-select-option{
    width: 180px !important;
}
.watchersList .customDropdown .react-dropdown-select-option-remove{
    width: 10px !important;
    border-radius: 0 !important;
}
.watchersList .customDropdown .react-dropdown-select-option .react-dropdown-select-option-label {
    width: 150px !important;
}

svg,.sidebar li{cursor: pointer;}
.chakra-avatar .chakra-avatar__initials {
    font-size: 10px!important;
}
li.chakra-wrap__listitem.theme-color svg {
    font-size: 24px;
    color: #6c7280!important;
}
.header .support-btn{
    border: 1px solid #2b6cb0!important;
    padding: 2px 8px!important;
    font-size: 12px!important;
    border-radius: 15px;
    color: #2b6cb0;
    font-family: "Inter",sans-serif!important;
    font-weight: 500!important;
    display: block;
}
.header .help svg{
    color: #6c7280!important;
    width: 24px;
    height: 24px;
}
.header .help .tool-tip{
    color: #fff;
    background: rgba(0,0,0,0.9);
    font-size: 12px;
    border-radius: 2px;
    font-weight: 500;
    padding: 2px 5px;
    opacity: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    bottom: -25px;
    left: 0px;
}
.header .help:hover .tool-tip{
    opacity: 1;
}
.header .chakra-header-btn{
    margin-left:13px;
}
.header .support-btn{
    cursor: pointer;
}
.header .support-btn i {
    padding-right: 2px;
    font-size: 14px;
    font-weight: 500;
}
.nav-item.nav-dropdown svg{
    color: #94a3b8 !important;
    line-height: 1em;
    flex-shrink: 0;
    text-indent: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-item.nav-dropdown i{
    color: #94a3b8 !important;
    height: 28px;
    width: 23.8px;
    line-height: 1em;
    flex-shrink: 0;
    text-indent: 100;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.menu-icon i{
    font-size: 20px;
}
.childmenu-item:hover{
    color: #1e90ff !important;
}
.sidebar ul li .menu:hover .menu-link+span .label{
    color:#1e90ff !important;
}
.sidebar ul li .menu:hover .menu-link span svg,.sidebar ul li .menu:hover .menu-link span i{
    color:#1e90ff !important;
}
.sidebar ul li .menu:hover .sub-menu-link span .label{
    color:#1e90ff !important;
}
.sidebar ul li .menu:hover .sub-menu-link span svg,.sidebar ul li .menu:hover .sub-menu-link span i{
    color:#1e90ff !important;
}
.sidebar .nav-link.active{
    color: #1e90ff !important;
    background: #ebf7ff;
    border-radius: 5px;
}
.sidebar .nav-link.active svg,.sidebar .nav-link.active i{
    color: #1e90ff !important;
}.sidebar.closed .nav-link.active{
    background-color: #fff !important;
}
.footer:hover a{
    color: #1e90ff !important;
}
.menu{
    position:relative;
}
.menu.submenu::before{
    display: block!important;
    width: 0.875rem;
    height: 0.875rem;
    padding: 0;
    font-size: .875rem;
    line-height: .65625rem;
    text-align: center;
    content: "\2039";
    transition: .3s;
    z-index: 9;
    transform: rotate(180deg);
    position: absolute;
    top: 13px;
    right: 12px;
    font-size: 16px!important;
    color: #c4c4c4;
    font-weight: 500;
}
.sidebar.closed .menu.submenu::before,.sidebar.closed .menu.childmenu::before{
    display: none !important;
}
.dropdown-toggle .menu.submenu::before{
    top: 14px;
    right: 12px;
    transform: rotate(270deg);
    color: #c4c4c4;
    font-size: 16px!important;
    transition: all .3s ease-in-out;
}
.menu.childmenu::before{
    display: block!important;
    width: 0.875rem;
    height: 0.875rem;
    padding: 0;
    font-size: .875rem;
    line-height: .65625rem;
    text-align: center;
    content: "\2039";
    transition: .3s;
    z-index: 9;
    transform: rotate(180deg);
    position: absolute;
    top: 6px;
    right: 12px;
    font-size: 16px!important;
    color: #c4c4c4;
    font-weight: 500;
}
.submenu-dropdown-toggle .menu.childmenu::before{
    top: 8px;
    right: 12px;
    color: #c4c4c4;
    font-size: 16px!important;
    transform: rotate(270deg);
    transition: all .3s ease-in-out;
}
.menu-icon i,.sub-menu-icon i{
    font-size: 20px !important;
    /* margin-right:3px; */
    width:20px;
    height:20px;
}
i.fa.fa-cubes {
    font-size: 17px!important;
}
.menu-icon svg{
    /* margin-right:3px; */
    width: 23.8px;
    height: 28px;
}
.sub-menu-icon svg{
    width: 23.8px;
    height: 28px;
}
.sidebar-menu-list::-webkit-scrollbar {
        width: 6px; 
    }
.sidebar-menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
.sidebar-menu-list::-webkit-scrollbar-thumb {
    background: #888;
  }
  li.submenu-item.nav-item.nav-dropdown.submenu-dropdown-toggle + li {
    padding-top: 0;
}
.main-page .css-td0epc {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: calc(100vh - 126.5px) !important;
    min-height: calc(100vh - 126.5px) !important;
    background: inherit !important;
}
.sidebar + div .css-1xi6o5t {
    height: calc(100vh - 126.5px);
    background: #eff5fe;
}
table thead tr th{
    padding: 8px 4px !important;
}
table tbody tr td{
    padding:12px 4px !important;
    font-size: 14px !important;
    
}
.upload-btn:hover{
    background-color: #1900ff !important;
}
.chakra-ui-dark table label.chakra-checkbox.css-1lc114p{
    border:#c2c2c2 !important;
}
.chakra-ui-dark .header,.chakra-ui-dark .footer{
    background-color: #fff !important;
}
.chakra-ui-dark .header select{
    border: 1px solid #c2c2c2;
    color: rgb(108, 114, 128);
}
.chakra-ui-dark .header .chakra-select__wrapper svg{
    color: rgb(108, 114, 128) !important;
}
.chakra-ui-dark .search-wrapper input{
    border: 1px solid #c2c2c2;
    color: rgb(108, 114, 128);
}
.chakra-ui-dark .search-wrapper input+div{
    max-height: 36px;
}
.chakra-ui-light .search-wrapper input+div{
    max-height: 36px;
}


.page-title {
    font-size: 20px !important;
    color: #111827 !important;
    font-weight: 600 !important;
    line-height: normal !important;
}
.primary-button {
   font-size: 14px !important;
   color: white !important;
   font-weight: 500 !important;
   line-height: 20px !important;
   padding: 9px 17px !important;
   border-radius: 5px !important;
   background-color: #2563eb !important;
}
/* .primary-button:hover {
    background-color: #0047E3 !important;
} */
.secondaryButton {
    background-color: #fff !important;
    border: 1px solid #D1D5DB !important;
    border-radius: 5px !important;
    color: #6b7280 !important;
    padding: 6px 15px !important;
    cursor: pointer !important;
    font-weight: 500 !important;
  }
  .secondaryButton:hover {
    background-color: #f5f5f5 !important;
  }
  table tbody tr td {
    border-bottom: 1px solid #D1D5DB !important;
    font-size: 14px !important;
    color: #6b7280 !important;
    font-weight: 400 !important;
  }
  table thead th {
    border-bottom: 1px solid #D1D5DB !important;
  }
  .input-label {
    font-size: 14px !important;
    color: #374151 !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding-bottom: 4px !important;
    font-family: "Inter",sans-serif!important;
  }
  .modal-title {
    font-size: 16px !important;
    color: #111827 !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }
  .react-dropdown-select-content span{
    margin: 0px !important;
  }
  .chakra-input {
    border: 1px solid #D1D5DB !important;
  }
  .create-article .ql-toolbar {
    border: 1px solid #D1D5DB !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
  .create-article .ql-container {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border: 1px solid #D1D5DB !important;
  }
  .css-ddil6j:focus, .css-ddil6j[data-focus] {
    box-shadow: none !important;
  }
  .css-1o5fdv0-ReactDropdownSelect:focus, .css-1o5fdv0-ReactDropdownSelect:focus-within{
    box-shadow: none !important;
  }
  .queue-dropdown .react-dropdown-select .react-dropdown-select-item {
    padding: 4px 6px !important;
    color: #6B7280 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  .queue-dropdown .react-dropdown-select .react-dropdown-select-item-selected {
    background-color: white !important;
    color: #111827 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .queue-dropdown .react-dropdown-select-dropdown {
    padding: 10px !important;
    border-radius: 6px !important;
  }




/* scrollbar theme */
::-webkit-scrollbar {
    width: 10px;
    padding:4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #D1D5DB; 
   padding: 0 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #11182733; 
    border-radius: 100px;
     border-left: 3px solid #D1D5DB;
    border-right: 3px solid #D1D5DB;
  
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .custom-table-header-th {
    margin-top: 0px !important;
  }
  .documents table thead tr th {
    padding: 14px 10px !important;
  }

  .documents table tbody tr td {
    padding: 15px 10px !important;
  }
  .sharepoint-custom-table-head {
    margin-top: 0px !important;
  }

  .sharepoint table thead tr th {
    padding: 14px 10px !important;
  }
  .crawl-container {
    margin-top: 0px !important;
  }

  .crawl-container table thead tr th {
    padding: 14px 5px !important;
  }

  .chakra-select {
    border: 1px solid #D1D5DB !important;
  }

  .chakra-table__container {
    overflow-y: auto !important;
  }
  .chakra-ui-dark .chakra-menu__menu-list{
    background: #fff !important;
  }
  .chakra-ui-dark .chakra-menu__menu-list button:hover{
    background-color: #edf2f7 !important;
  }
  .chakra-ui-dark .chakra-switch>span {
    background-color: #cbd5e0 !important;
}
.chakra-ui-dark .chakra-input {
  border: 1px solid #D1D5DB !important;
}
.ImportKnowledge{
  font-family: "Inter",sans-serif!important;
  color: #6B7280 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 4px 6px !important;
}
.chakra-ui-dark .chakra-input::placeholder {
  color:#A9B1BC;
}
/* toolbar styles */

.dark-mode .ql-toolbar {
    background-color: #2d3748; /* set the background color for the toolbar */
    color: #fff; /* set the text color for the toolbar */
  }
  
  .dark-mode .ql-toolbar .ql-stroke {
    fill: none;
    stroke: #fff;
  }
  
  .dark-mode .ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
  }
  
  .dark-mode .ql-toolbar .ql-picker {
    color: #fff;
  }
  
  /* placeholder text styles */
  .dark-mode .ql-editor::before {
    color: #a0aec0; /* set the color for the placeholder text */
  }
  
/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1400; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.48); /* Black w/ opacity */
  }
  
  /* Modal Content */
  .modal-content {
      width: 33%;
      margin-right: 11%;
     
      background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    
  }
  
  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .videoFileUploadBtn {
    margin-top:12px;
  }

  .videoFileInput {
    margin-top:12px;
  }
  .modelHeading {
    margin-bottom:12px;
  }
  .videoUrlTextBox {
    border: 1px solid gray;
    margin-top: 12px;
  }
  .error {
  color: red;
  }
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .button {
      background-color: #4CAF50 !important; /* Green */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      width: 40%;
      margin-right: 26px;
    }

    /* Date 17-05-23 for model popup css   */
    .modal-content.mcp_p {
      width: 60%;
    }
    .custom_upload {
      display: flex;
      height: 86px;
      border: 2px dashed #c4c4c4;
      position: relative;
  }
  .custom_upload:after {
    position: absolute;
    /* height: 30px; */
    /* width: 100%; */
    content: "Click here to upload file";
    background: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #787878;
}
.videoFileInput {
    margin-top: 0;
    opacity: 0;
    position: relative;
    z-index: 3;
    width: 100%;
}

.videoUrlTextBox:focus-visible {
  border: 0px;
  border-bottom: 1px;
  outline-color: blue !important;
 
}

.btnupload {
  text-align: center!important;
}
.rdw-image-modal-btn-section {
  margin: 10px auto 0;
}


.btnupload button {
  background: #eaeaea!important;
}
/**/
.rdw-image-modal-btn {
  padding: 2px 6px;
  font-size: 14px;
  color: #333;

}
.inputimghw 
{
  padding: 0 10px;
  height: 28px;
  margin: 10px;
}
.inputimghwupload 
{
  padding: 0 10px;
  height: 28px;
  margin-top:20px;
  margin-right:10px;
}
/**/ 
.videoUrlTextBox {
  border: 0px;
  margin-top: 12px;
  border-bottom: 1px solid #bdbdbd;
  width: 100%;
  padding: 4px 0;
  margin-bottom: 4px;
}
.imageHWContainer {
    justify-content: space-between;
    margin-bottom: 10px;
}
blockquote {
display: block !important;
-webkit-margin-before: 1em !important;
        margin-block-start: 1em !important;
-webkit-margin-after: 1em !important;
        margin-block-end: 1em !important;
-webkit-margin-start: 40px !important;
        margin-inline-start: 40px !important;
-webkit-margin-end: 40px !important;
        margin-inline-end: 40px !important;
}
.cannedNotes pre {
  width: 350px;
    white-space: nowrap;
    overflow: inherit;
    text-overflow: ellipsis;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-indent-1 {
  padding-left:3em
}
.ql-indent-2 {
  padding-left:6em
}
.ql-indent-3 {
  padding-left:9em
}
.ql-indent-4 {
  padding-left:12em
}
.ql-indent-5 {
  padding-left:15em
}
.ql-indent-6 {
  padding-left:18em
}
.ql-indent-7 {
  padding-left:21em
}
.ql-indent-8 {
  padding-left:24em
}
.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align:justify
}
.ql-align-right {
  text-align:right
}
.ql-tooltip.ql-editing {
  left:28px !important;
  z-index:999999
}
.ql-tooltip {
  z-index:999999;
  left:28px !important;

}
.ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
  /* padding-left: 0.5em !important;*/
}

.chakra-ui-dark .ql-picker-options{
background-color :#2D3748 !important;
}
.chakra-spinner {
  z-index: 9999 !important;
  /* position: absolute !important; */
}

.ql-mention-list-container {
  height: auto;
  max-height: 200px; 
  overflow: auto; 
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.quill-mention-list {

position: absolute;
background-color: #fff;
min-width: 200px;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
z-index: 1;
}
.ql-mention-list-item {
  padding: 3px;
  text-decoration: none;
  display: block;
  color:blue;

}
.ql-mention-list-item li:focus,
.ql-mention-list-item li:focus-within {
  background-color: red;
  outline: 2px solid blue;
  /* Add any other styles to highlight the item, such as color or border */
}
.mention {
  color: blue;
}
.chakra-ui-light .ql-mention-list-item.selected {
  background-color: #f5f5f5;
}
.chakra-ui-dark .ql-mention-list-item.selected {
  background-color: #CCCCB4;
}

.chakra-ui-light td .rdw-mention-link {
  color: black !important;
}
.chakra-ui-dark td .rdw-mention-link {
  color: white !important;
}
.chakra-ui-dark .ql-snow .ql-picker{
  color: white !important;
  /* background-color: #2d3748;*/
}

.chakra-ui-dark .ql-mention-list-container.ql-mention-list-container-bottom {
  background-color: #2d3748;
}

.autocomplete .parent:hover .child {
  visibility: visible;
  opacity: 1;
}

.autocomplete .child {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}
iframe {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.ql-toolbar .ql-disabled {
    opacity: 0.5;
    cursor:not-allowed !important;
}

.ql-toolbar .ql-disabled svg{
    cursor: not-allowed !important;
}

.autocomplete .parent:hover .child {
    visibility: visible;
    opacity: 1;
}
  
.autocomplete .child {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
}
#exception {
    border: 2px solid transparent;
    background: linear-gradient(#F4F4F4, #f4f4f4) padding-box, linear-gradient(var(--angle),
            #fff,
            #2563EB) border-box;
    animation: 5s rotate ease infinite;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.convo-wrapper.clearfix .ac-pushButton {
    width: 100% !important;
}

.convo-wrap.from-bot button.ac-pushButton {
    border: 1px solid #e2e2e2 !important;
    border-radius: 50px !important;
    color: #c4c4c4 !important;
    width: 157px;
    margin: 0px auto;
    text-align: center;
    padding: 5px 6px;
    font-size: 13px;
    color: #999999 !important;
}

.convo-wrap.from-bot button.ac-pushButton:hover {
    background-color: #e85a5b !important;
    color: #fff !important;
    transition: all 0.3s;
    border: 1px solid #e85a5b !important;
    text-decoration: none !important;
}

/* .convo-wrap.from-bot button.ac-pushButton {
	margin-bottom: 13px;
} */

.convo-wrapper.clearfix p .ac-pushButton {
    width: 100% !important;
}

input.ac-input,
select.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
    height: 35px;
    border-radius: 5px;
    border: 0px;
    color: #6f7d8c;
    font-size: 14px;
    padding: 0px 10px;
    background-color: #fff;
}

.ac-input {
    border-radius: 4px;
    border: 1px solid #e2e2e2 !important;
    padding: 6px 20px !important;
    position: relative !important;
    color: #6f7d8c;
}

.back-btn svg {
    font-size: 28px;
}
.convo-wrapper.clearfix .ac-pushButton {
    width: 100%!important;
}
.convo-wrap.from-bot button.ac-pushButton {
	border: 1px solid #e2e2e2 !important;
	border-radius: 50px !important;
	color: #c4c4c4 !important;
	width: 157px;
	margin: 0px auto;
	text-align: center;
	padding: 5px 6px;
	font-size: 13px;
	color: #999999 !important;
}

.convo-wrap.from-bot button.ac-pushButton:hover {
	background-color: #e85a5b !important;
	color: #fff !important;
	transition: all 0.3s;
	border: 1px solid #e85a5b !important;
	text-decoration: none !important;
}
/* .convo-wrap.from-bot button.ac-pushButton {
	margin-bottom: 13px;
} */

.convo-wrapper.clearfix p .ac-pushButton {
	width: 100% !important;
}
input.ac-input, select.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
    height: 35px;
    border-radius: 5px;
    border: 0px;
    color: #6f7d8c;
    font-size: 14px;
    padding: 0px 10px;
    background-color: #fff;
}

.ac-input {
	border-radius: 4px;
	border: 1px solid #e2e2e2 !important;
	padding: 6px 20px !important;
	position: relative !important;
    color: #6f7d8c;
}
.back-btn svg{
	font-size: 28px;
}

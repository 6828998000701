@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@import 'font-awesome/css/font-awesome.min.css';
svg,.sidebar li{cursor: pointer;}
.chakra-avatar .chakra-avatar__initials {
    font-size: 10px!important;
}
li.chakra-wrap__listitem.theme-color svg {
    font-size: 24px;
    color: #6c7280!important;
}
.header .support-btn{
    border: 1px solid #2b6cb0!important;
    padding: 2px 8px!important;
    font-size: 12px!important;
    border-radius: 15px;
    color: #2b6cb0;
    font-family: "Inter",sans-serif!important;
    font-weight: 500!important;
    display: block;
}
.header .help svg{
    color: #6c7280!important;
    width: 24px;
    height: 24px;
}
.header .help .tool-tip{
    color: #fff;
    background: rgba(0,0,0,0.9);
    font-size: 12px;
    border-radius: 2px;
    font-weight: 500;
    padding: 2px 5px;
    opacity: 0;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    position: absolute;
    bottom: -25px;
    left: 0px;
}
.header .help:hover .tool-tip{
    opacity: 1;
}
.header .chakra-header-btn{
    margin-left:13px;
}
.header .support-btn{
    cursor: pointer;
}
.header .support-btn i {
    padding-right: 2px;
    font-size: 14px;
    font-weight: 500;
}
.nav-item.nav-dropdown svg{
    color: #94a3b8 !important;
    line-height: 1em;
    flex-shrink: 0;
    text-indent: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-item.nav-dropdown i{
    color: #94a3b8 !important;
    height: 28px;
    width: 23.8px;
    line-height: 1em;
    flex-shrink: 0;
    text-indent: 100;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.menu-icon i{
    font-size: 20px;
}
.childmenu-item:hover{
    color: #1e90ff !important;
}
.sidebar ul li .menu:hover .menu-link+span .label{
    color:#1e90ff !important;
}
.sidebar ul li .menu:hover .menu-link span svg,.sidebar ul li .menu:hover .menu-link span i{
    color:#1e90ff !important;
}
.sidebar ul li .menu:hover .sub-menu-link span .label{
    color:#1e90ff !important;
}
.sidebar ul li .menu:hover .sub-menu-link span svg,.sidebar ul li .menu:hover .sub-menu-link span i{
    color:#1e90ff !important;
}
.sidebar .nav-link.active{
    color: #1e90ff !important;
    background: #ebf7ff;
    border-radius: 5px;
}
.sidebar .nav-link.active svg,.sidebar .nav-link.active i{
    color: #1e90ff !important;
}.sidebar.closed .nav-link.active{
    background-color: #fff !important;
}
.footer:hover a{
    color: #1e90ff !important;
}
.menu{
    position:relative;
}
.menu.submenu::before{
    display: block!important;
    width: 0.875rem;
    height: 0.875rem;
    padding: 0;
    font-size: .875rem;
    line-height: .65625rem;
    text-align: center;
    content: "\2039";
    transition: .3s;
    z-index: 9;
    transform: rotate(180deg);
    position: absolute;
    top: 13px;
    right: 12px;
    font-size: 16px!important;
    color: #c4c4c4;
    font-weight: 500;
}
.sidebar.closed .menu.submenu::before,.sidebar.closed .menu.childmenu::before{
    display: none !important;
}
.dropdown-toggle .menu.submenu::before{
    top: 14px;
    right: 12px;
    transform: rotate(270deg);
    color: #c4c4c4;
    font-size: 16px!important;
    transition: all .3s ease-in-out;
}
.menu.childmenu::before{
    display: block!important;
    width: 0.875rem;
    height: 0.875rem;
    padding: 0;
    font-size: .875rem;
    line-height: .65625rem;
    text-align: center;
    content: "\2039";
    transition: .3s;
    z-index: 9;
    transform: rotate(180deg);
    position: absolute;
    top: 6px;
    right: 12px;
    font-size: 16px!important;
    color: #c4c4c4;
    font-weight: 500;
}
.submenu-dropdown-toggle .menu.childmenu::before{
    top: 8px;
    right: 12px;
    color: #c4c4c4;
    font-size: 16px!important;
    transform: rotate(270deg);
    transition: all .3s ease-in-out;
}
.menu-icon i,.sub-menu-icon i{
    font-size: 20px !important;
    /* margin-right:3px; */
    width:20px;
    height:20px;
}
i.fa.fa-cubes {
    font-size: 17px!important;
}
.menu-icon svg{
    /* margin-right:3px; */
    width: 23.8px;
    height: 28px;
}
.sub-menu-icon svg{
    width: 23.8px;
    height: 28px;
}
.sidebar-menu-list::-webkit-scrollbar {
        width: 6px; 
    }
.sidebar-menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
.sidebar-menu-list::-webkit-scrollbar-thumb {
    background: #888;
  }
  li.submenu-item.nav-item.nav-dropdown.submenu-dropdown-toggle + li {
    padding-top: 0;
}
.main-page .css-td0epc {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: calc(100vh - 126.5px) !important;
    min-height: calc(100vh - 126.5px) !important;
    background: inherit !important;
}
.sidebar + div .css-1xi6o5t {
    height: calc(100vh - 126.5px);
    background: #eff5fe;
}
table thead tr th{
    padding: 8px 4px !important;
}
table tbody tr td{
    padding:12px 4px !important;
    font-size: 14px !important;
    
}
.upload-btn:hover{
    background-color: #1900ff !important;
}
.chakra-ui-dark table label.chakra-checkbox.css-1lc114p{
    border:#c2c2c2 !important;
}
.chakra-ui-dark .header,.chakra-ui-dark .footer{
    background-color: #fff !important;
}
.chakra-ui-dark .header select{
    border: 1px solid #c2c2c2;
    color: rgb(108, 114, 128);
}
.chakra-ui-dark .header .chakra-select__wrapper svg{
    color: rgb(108, 114, 128) !important;
}
.chakra-ui-dark .search-wrapper input{
    border: 1px solid #c2c2c2;
    color: rgb(108, 114, 128);
}
.chakra-ui-dark .search-wrapper input+div{
    max-height: 36px;
}
.chakra-ui-light .search-wrapper input+div{
    max-height: 36px;
}


.page-title {
    font-size: 20px !important;
    color: #111827 !important;
    font-weight: 600 !important;
    line-height: normal !important;
}
.primary-button {
   font-size: 14px !important;
   color: white !important;
   font-weight: 500 !important;
   line-height: 20px !important;
   padding: 9px 17px !important;
   border-radius: 5px !important;
   background-color: #2563eb !important;
}
/* .primary-button:hover {
    background-color: #0047E3 !important;
} */
.secondaryButton {
    background-color: #fff !important;
    border: 1px solid #D1D5DB !important;
    border-radius: 5px !important;
    color: #6b7280 !important;
    padding: 6px 15px !important;
    cursor: pointer !important;
    font-weight: 500 !important;
  }
  .secondaryButton:hover {
    background-color: #f5f5f5 !important;
  }
  table tbody tr td {
    border-bottom: 1px solid #D1D5DB !important;
    font-size: 14px !important;
    color: #6b7280 !important;
    font-weight: 400 !important;
  }
  table thead th {
    border-bottom: 1px solid #D1D5DB !important;
  }
  .input-label {
    font-size: 14px !important;
    color: #374151 !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    padding-bottom: 4px !important;
    font-family: "Inter",sans-serif!important;
  }
  .modal-title {
    font-size: 16px !important;
    color: #111827 !important;
    font-weight: 600 !important;
    line-height: 20px !important;
  }
  .react-dropdown-select-content span{
    margin: 0px !important;
  }
  .chakra-input {
    border: 1px solid #D1D5DB !important;
  }
  .create-article .ql-toolbar {
    border: 1px solid #D1D5DB !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
  .create-article .ql-container {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border: 1px solid #D1D5DB !important;
  }
  .css-ddil6j:focus, .css-ddil6j[data-focus] {
    box-shadow: none !important;
  }
  .css-1o5fdv0-ReactDropdownSelect:focus, .css-1o5fdv0-ReactDropdownSelect:focus-within{
    box-shadow: none !important;
  }
  .queue-dropdown .react-dropdown-select .react-dropdown-select-item {
    padding: 4px 6px !important;
    color: #6B7280 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  .queue-dropdown .react-dropdown-select .react-dropdown-select-item-selected {
    background-color: white !important;
    color: #111827 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
  .queue-dropdown .react-dropdown-select-dropdown {
    padding: 10px !important;
    border-radius: 6px !important;
  }




/* scrollbar theme */
::-webkit-scrollbar {
    width: 10px;
    padding:4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #D1D5DB; 
   padding: 0 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #11182733; 
    border-radius: 100px;
     border-left: 3px solid #D1D5DB;
    border-right: 3px solid #D1D5DB;
  
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .custom-table-header-th {
    margin-top: 0px !important;
  }
  .documents table thead tr th {
    padding: 14px 10px !important;
  }

  .documents table tbody tr td {
    padding: 15px 10px !important;
  }
  .sharepoint-custom-table-head {
    margin-top: 0px !important;
  }

  .sharepoint table thead tr th {
    padding: 14px 10px !important;
  }
  .crawl-container {
    margin-top: 0px !important;
  }

  .crawl-container table thead tr th {
    padding: 14px 5px !important;
  }

  .chakra-select {
    border: 1px solid #D1D5DB !important;
  }

  .chakra-table__container {
    overflow-y: auto !important;
  }
  .chakra-ui-dark .chakra-menu__menu-list{
    background: #fff !important;
  }
  .chakra-ui-dark .chakra-menu__menu-list button:hover{
    background-color: #edf2f7 !important;
  }
  .chakra-ui-dark .chakra-switch>span {
    background-color: #cbd5e0 !important;
}
.chakra-ui-dark .chakra-input {
  border: 1px solid #D1D5DB !important;
}
.ImportKnowledge{
  font-family: "Inter",sans-serif!important;
  color: #6B7280 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  padding: 4px 6px !important;
}
.chakra-ui-dark .chakra-input::placeholder {
  color:#A9B1BC;
}